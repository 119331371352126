/*
 * @Author: 陈剑伟
 * @Date: 2020-05-19 14:10:56
 * @LastEditTime: 2020-06-15 16:00:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\course.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.courseStartTime = ''
      this.courseEndTime = ''
      this.courseEndTimes = []
      this.deadLineStartTime = ''
      this.deadLineEndTime = ''
      this.deadLineEndTimes = []
      this.searchValue = ''
      this.publishStatus = ''
      this.top = ''
      this.coachIds = ''
    }
  }
}
export default searchFrom
