/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2021-01-21 10:45:55
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePrice = (rule, value, callback) => {
    if (!value) {
      callback([new Error('请输入课程价格')]);
    } else {
        if (Number(value) <= 0) {
            callback([new Error('课程价格不能为0')]);
        } else if (Number(value) < 0) {
          callback([new Error('课程价格不能为负数')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.status = 'first'
      this.name = ''
      this.type = ''
      this.registerCodes = ['name', 'mobile']
      this.duration = ''
      this.detail = ''
      this.introduction = ''
      this.orgPrice = ''
      this.price = ''
      this.peopleQty = ''
      this.courseQty = ''
      this.coachId = ''
      this.coachIds = ''
      this.timeLine = ''
      this.deadLine = ''
      this.startTime = ''
      this.picturePath = ''
      this.pictureUrl = ''
      this.files = []
      this.classify = ''
      // 无需传给后端
      this.filesPicturesPath = []
    } else if (type === 'rule') {
      this.registerCodes = [
        {
          required: true,
          message: '请选择报名信息',
          trigger: 'change',
        },
      ]
      this.coachId = [
        {
          required: true,
          message: '请选择教练',
          trigger: 'change',
        },
      ]
      this.name = [
        {
          required: true,
          message: '请输入课程名称',
          trigger: 'change',
        },
      ]
      this.type = [
        {
          required: true,
          message: '请选择课程类型',
          trigger: 'change',
        },
      ]
      this.classify = [
        {
          required: true,
          message: '请选择课程分类',
          trigger: 'change',
        },
      ]
      this.duration = [
        {
          required: true,
          message: '请输入时长',
          trigger: 'change',
        },
      ]
      this.introduction = [
        {
          required: true,
          message: '请输入课程简介',
          trigger: 'change',
        },
      ]
      this.orgPrice = [
        {
          required: false,
          message: '请输入原价',
          trigger: 'change',
        },
      ]
      this.price = [
        {
          required: true,
          validator: this.validatePrice,
          trigger: 'change',
        },
      ]
      this.peopleQty = [
        {
          required: true,
          message: '请输入课程人数',
          trigger: 'change',
        },
      ]
      this.courseQty = [
        {
          required: true,
          message: '请输入课程节数',
          trigger: 'change',
        },
      ]
      this.coach = [
        {
          required: true,
          message: '请输入教练',
          trigger: 'change',
        },
      ]
      this.deadLine = [
        {
          required: true,
          message: '请输入选择报名截止时间',
          trigger: 'change',
        },
      ]
      this.startTime = [
        {
          required: true,
          message: '请输入选择课程开始时间',
          trigger: 'change',
        },
      ]
      this.detail = [
        {
          required: true,
          message: '请输入课程详情介绍',
          trigger: 'change',
        },
      ]
      this.picturePath = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
