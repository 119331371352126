<!--
 * @Author: genwai
 * @Date: 2020-05-21 14:07:33
 * @LastEditTime: 2021-02-04 19:48:53
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\component\preview.vue
-->
<template>
  <div class="image-preview-wrapper">
    <el-dialog
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :show-close="false"
      class="image-preview-applet-content"
      :close-on-click-modal="false"
      width="100%"
    >
      <div class="out-wrapper">
        <div class="pre-list-item">
          <div class="list-item start">
            <img :src="form.pictureUrl" alt="" srcset="" class="img" />
            <div class="list-item-con">
              <div class="course-top">
                <div class="left">{{ form.name }}</div>
                <div class="right">12km</div>
              </div>
              <div class="course-item-center">{{ form.address }}</div>
              <div class="course-item-bottom">
                <span class="price"
                  ><span style="font-size: 10px">￥</span>{{ form.price }}</span
                >
                <span class="origin-price">¥{{ form.orgPrice }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="img-wrapper">
          <div class="applet-wrapper">
            <div class="applet-head">
              <img src="@/assets/images/pre_banner.png" alt="" srcset="" />
              <div class="name">课程详情</div>
            </div>
            <div class="applet-content">
              <img class="image" :src="form.pictureUrl" alt="" />
              <img
                class="collection"
                src="@/assets/images/pre_collection.png"
                alt=""
              />
              <div class="applet-contaner">
                <div class="course-infor">
                  <div class="infor-top">
                    <div class="left">
                      <div class="name">{{ form.name }}</div>
                      <div class="price">
                        <span class="price">￥{{ form.price }}</span
                        ><span class="ori-price">￥{{ form.orgPrice }}</span>
                      </div>
                    </div>
                    <div class="right">
                      <img
                        class="images"
                        src="@/assets/images/pre_phone.png"
                        alt=""
                      />
                      <div class="call">拨打</div>
                    </div>
                  </div>
                  <div class="info-center">
                    <div class="left">
                      <img
                        class="buy-images"
                        src="@/assets/images/pre_buy.png"
                        alt=""
                      />
                      购买后360天有效
                    </div>
                    <div class="right">已报名14人</div>
                  </div>
                  <div class="info-bottom">
                    <img
                      class="images"
                      src="@/assets/images/pre_pos.png"
                      alt=""
                    />
                    <div class="right">{{ form.address }}</div>
                  </div>
                </div>
                <div class="coach-content">
                  <div class="title">教练信息</div>
                  <div class="coach-container">
                    <img class="images" :src="form.avatarUrl" alt="" />
                    <div class="right">
                      <div class="top">教练：{{ form.coachName }}</div>
                      <div class="center">{{ form.introduction }}</div>
                      <div class="bottom">
                        <div class="left">
                          {{ form.typeName }} ({{ form.venueName }})
                        </div>
                        <!-- <div class="right">更多相关课程</div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="course-content">
                  <!-- <div class="title">课程介绍</div> -->
                  <div class="content" v-html="form.detail"></div>
                </div>
                <div class="course-bottom">
                  <div class="share-btn">
                    <img
                      class="images"
                      src="@/assets/images/pre_share.png"
                      alt=""
                      srcset=""
                    />
                    <div class="text">分享</div>
                  </div>
                  <div class="left">立即报名</div>
                </div>
              </div>
            </div>
          </div>
          <div class="preview-over" @click="closeDialog">结束预览</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "../dialog/addDialogClass.js";

export default {
  mixins: [mixin],
  //部件
  components: {},
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    src: {
      type: String,
      default: "",
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"),
    };
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getPreviewData(preData) {
      // 在这里获取回显的数据
      this.$http
        .post(apis.courseManagePreview, {
          coachId: preData.coachIds[0] || preData.coaches[0].id,
        })
        .then((res) => {
          if (res.data.code === 0) {
            console.log("getDataQuery -> data111111", res.data, preData);
            this.form = {
              ...preData,
              address: res.data.data.venue.address || "",
              venueName: res.data.data.venue.venueName,
              avatarUrl: res.data.data.coachInfo.avatarUrl,
              coachName: res.data.data.coachInfo.coachName,
              introduction: res.data.data.coachInfo.introduction,
              price: Number(preData.price).toFixed(2),
              orgPrice: preData.orgPrice
                ? Number(preData.orgPrice).toFixed(2)
                : Number(preData.price).toFixed(2),
            };
            this.DROPDOWNBOX.VENUE_COURSE_TYPE.forEach((item) => {
              if (item.value === res.data.data.coachInfo.type) {
                this.form.typeName = item.label
              }
            })
          }
        });
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>
<style>
.content img {
  max-width: 100%;
}
</style>
<style lang="less" scoped>
.image-preview-applet-content {
  .out-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .pre-list-item {
    width: 375px;
    margin-right: 20px;
    .list-item {
      padding: 8px;
      display: flex;
      background-color: #ffffff;
      margin-bottom: 1rpx;
      border-radius: 6px;
      color: #999999;
      .img {
        width: 117px;
        height: 76px;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .list-item-con {
        width: 50%;
        flex-grow: 1;
        // height: 152px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        .des {
          width: 100%;
          font-size: 10px;
          color: #999999;
          .distance {
            width: 100%;
            margin-top: 14px;
          }
        }
      }
      .course-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 500;
          font-weight: bold;
          color: rgba(23, 32, 66, 1);
          font-size: 15px;
        }
        .right {
          font-size: 10px;
          color: rgba(153, 153, 153, 1);
          font-weight: 400;
        }
      }
      .course-item-center {
        line-height: 14px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        font-size: 10px;
        margin-top: 4px;
      }
      .course-item-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 17px;
        .price {
          font-weight: 400;
          color: rgba(223, 33, 17, 1);
          font-size: 14px;
          margin-right: 10px;
        }
        .origin-price {
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          font-size: 10px;
          text-decoration: line-through;
        }
      }
    }
  }
  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .preview-over {
    cursor: pointer;
    height: 49px;
    width: 375px;
    line-height: 49px;
    background: rgba(61, 100, 236, 1);
    border-radius: 25px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  .applet-wrapper {
    width: 375px;
  }
  .applet-head {
    background: #3d64ec;
    height: 64px !important;
    height: 100%;
    position: relative;
    .name {
      font-size: 17px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 24px;
      position: absolute;
      left: 33px;
      bottom: 11px;
    }
  }
  .applet-content {
    background: #f7f8fa;
    padding-bottom: 20px;
    position: relative;
    .collection {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    .applet-contaner {
      margin-top: -32px;
      position: relative;
    }
    .course-infor {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 8px 12px 0px rgba(81, 113, 225, 0.08);
      border-radius: 10px;
      margin: 0 15px;
      padding: 12px 10px;
      .infor-top {
        display: flex;
        justify-content: space-between;
        .left {
          .name {
            font-weight: 500;
            color: rgba(23, 32, 66, 1);
            line-height: 22px;
            font-size: 15px;
            margin-bottom: 5px;
          }
          .price {
            font-size: 10px;
            font-weight: 400;
            color: rgba(223, 33, 17, 1);
            line-height: 14px;
          }
          .ori-price {
            font-size: 10px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 14px;
            text-decoration: line-through;
            margin-left: 3px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          width: 92px;
          border-left: 1px solid #dcdcdc;
          align-items: center;
          .images {
            width: 14px;
            height: 18px;
          }
          .call {
            color: rgba(61, 100, 236, 1);
            font-size: 10px;
          }
        }
      }
    }
    .info-center {
      background: rgba(249, 247, 241, 1);
      color: rgba(179, 136, 67, 1);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      font-weight: 400;
      margin-top: 8px;
      margin-bottom: 8px;
      .left {
        font-size: 15px;
        .buy-images {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .right {
        font-size: 12px;
      }
    }
    .info-bottom {
      color: rgba(82, 82, 82, 1);
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .images {
        width: 10px;
        height: 12px;
      }
      .right {
        margin-left: 3px;
      }
    }
    .image {
      height: 170px;
      width: 100%;
    }
  }
  .coach-content {
    margin: 20px 15px;
    .coach-container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .title {
      font-weight: 500;
      color: rgba(23, 32, 66, 1);
      font-size: 15px;
      margin-bottom: 8px;
    }
    .images {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    .right {
      margin-left: 10px;
      flex: 1;
      .top {
        font-weight: 400;
        color: rgba(23, 32, 66, 1);
        font-size: 14px;
        margin-bottom: 2px;
      }
      .center {
        color: rgba(153, 153, 153, 1);
        font-size: 24rpx;
        margin-bottom: 15rpx;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        color: rgba(61, 100, 236, 1);
        font-size: 12px;
        .right {
          text-align: right;
        }
      }
    }
  }
  .course-bottom {
    display: flex;
    flex-direction: row;
    margin: 0 15px;
    .share-btn {
      width: 49px;
      height: 49px;
      background: rgba(255, 255, 255, 1);
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(61, 100, 236, 0.11);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .images {
        width: 16px;
      }
      .text {
        margin-top: 2px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(61, 100, 236, 1);
        line-height: 14px;
      }
    }
    .left {
      height: 49px;
      flex: 1;
      line-height: 49px;
      background: rgba(61, 100, 236, 1);
      border-radius: 25px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: center;
      margin-left: 10px;
    }
  }
  .course-content {
    min-height: 250px;
    margin: 20px 15px;
    img {
      max-width: 100%;
    }
    .title {
      font-weight: 500;
      color: rgba(23, 32, 66, 1);
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
}
</style>
<style lang="less">
.image-preview-applet-content {
  .el-dialog {
    background: transparent !important;
  }
  .el-dialog__header {
    border-bottom: 0 !important;
  }
}
</style>
