<template>
  <div class="dialog-container weekBox">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="750px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-tabs class="taps-card" v-model="form.status">
          <el-tab-pane
            :label="`${isEdit ? '修改课程' : '新增课程'}`"
            name="first"
            style="padding-right: 10px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="课程名称" prop="name">
                  <el-input
                    clearable
                    placeholder="最多输入20字"
                    v-model="form.name"
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="报名截止时间" prop="deadLine">
                  <el-date-picker
                    v-model="form.deadLine"
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:00"
                    format="yyyy-MM-dd HH:mm:00"
                    type="datetime"
                    :picker-options="startTimeOptions"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="课程时间" prop="startTime">
                  <el-date-picker
                    style="width: 100%"
                    :picker-options="endTimeOptions"
                    v-model="form.startTime"
                    value-format="yyyy-MM-dd HH:mm:00"
                    format="yyyy-MM-dd HH:mm:00"
                    type="datetime"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="课程类型" prop="type">
                  <el-select v-model="form.type" style="width: 100%">
                    <el-option
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      :value="item.sportsProjectCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="课程类型" prop="type">
                  <el-select v-model="form.type" style="width:100%">
                    <el-option v-for="(item) in DROPDOWNBOX.VENUE_COURSE_TYPE" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="课程分类" prop="classify">
                  <el-select v-model="form.classify" style="width:100%">
                    <el-option v-for="(item) in DROPDOWNBOX.VENUE_COURSE_CLASSIFY" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="课程价格" prop="price">
                  <el-input
                    clearable
                    type="number"
                    @change="numberChange(arguments[0], 999999, 'price')"
                    v-model="form.price"
                    @blur="
                      () =>
                        (this.form.price = Number(this.form.price).toFixed(2))
                    "
                  >
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="课程原价" prop="orgPrice">
                  <el-input
                    clearable
                    type="number"
                    @change="numberChange(arguments[0],999999, 'orgPrice')"
                    v-model="form.orgPrice"
                    @blur="
                      () =>
                        (this.form.orgPrice = Number(
                          this.form.orgPrice
                        ).toFixed(2))
                    "
                  >
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="课程人数上限" prop="peopleQty">
                  <el-input clearable v-model="form.peopleQty" @change="numberChange(arguments[0],999999, 'peopleQty')" type="number">
                    <span slot="suffix">人</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="教练" prop="coachId">
                  <el-select
                    v-model="form.coachId"
                    style="width: 100%"
                    @change="onCoachHandle"
                  >
                    <el-option
                      v-for="item in coachList"
                      :key="item.id"
                      :label="item.coachName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="课程封面图片" prop="picturePath">
                  <el-upload
                    class="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png,.jpg"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.pictureUrl"
                      class="image-upload"
                      :src="form.pictureUrl"
                      alt=""
                      srcset=""
                      width="78"
                      height="78"
                    />
                    <img
                      v-else
                      class="image-upload"
                      src="@/assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于375*170px，图片格式png/jpg
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="课程简介" prop="introduction">
                  <el-input
                    clearable
                    placeholder="最多输入50字"
                    type="textarea"
                    :maxlength="50"
                    :rows="4"
                    v-model="form.introduction"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" class="footerBtn">
                <el-button class="footer-confirm" @click="goDetailed">下一步</el-button>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="课程详情" name="second">
            <el-col :span="24"
              ><div class="course-text">课程详情介绍（图片）</div></el-col
            >
            <el-col :span="24">
              <el-form-item prop="detail" label-width="0">
                <div class="edit_container">
                  <!-- <quill-editor v-model="form.detail" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)" @change="onEditChange">
                  </quill-editor> -->
                  <quill-edit
                    :detail="form.detail"
                    @change="onEditChange"
                    :qnLocation="apis.ImageUpload"
                  >
                  </quill-edit>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24"
              ><div class="course-text">报名需要信息</div></el-col
            >
            <el-col :span="24">
              <el-form-item label-width="0" prop="registerCodes">
                <el-checkbox-group
                  v-model="form.registerCodes"
                  size="mini"
                  class="course-checkbox"
                  @change="onApply"
                >
                  <el-checkbox
                    v-for="item in DROPDOWNBOX.VENUE_APPLY_INFO"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <template slot="footer" v-if="form.status === 'second'">
        <el-button class="footer-confirm" @click="onPreview">预览</el-button>
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <preview-image
      ref="previewImage"
      :show="previewImage.visible"
      @closeDialog="
        () => {
          this.previewImage.visible = false;
        }
      "
    ></preview-image>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import previewImage from "../component/preview.vue";
import quillEdit from "@/components/quillEdit.vue";

export default {
  components: {
    quillEdit,
    previewImage,
  },
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  /* eslint-disable */
  created() {
    console.log("created -> DROPDOWNBOX", this.DROPDOWNBOX.VENUE_APPLY_INFO);
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      typeList: [],
      apis,
      showPic: true,
      coachList: [], //教练集合
      applyInfoList: [], //报名列表集合
      editorOption: {
        placeholder: "请输入...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["image", "formula"], //去除video即可
          ],
        },
      },
      previewImage: {
        visible: false,
        src: "",
      },
      // 结束时间不能大于开始时间
      startTimeOptions: {
        disabledDate: (time) => {
          if (this.form.startTime) {
            return (
              time.getTime() > new Date(this.form.startTime).getTime() ||
              time.getTime() < Date.now() - 8.64e7
            );
          } else {
            //还没有选择结束时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      endTimeOptions: {
        disabledDate: (time) => {
          if (this.form.deadLine) {
            return time.getTime() < new Date(this.form.deadLine).getTime();
          } else {
            //还没有选择开始时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      sportList: [],
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN"]),
  },
  methods: {
    getSportList() {
      this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    onApply(value) {},
    onPickerChange(value) {
      this.form.startTime = value[0];
      this.form.deadLine = value[1];
    },
    /**
     * @function 文本编辑器
     */
    onEditorReady(editor) {
      console.log(editor);
    },
    onEditChange(value) {
      this.form.detail = value;
      this.$refs.form.validateField("detail");
    },
    onPreview() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.previewImage.visible = true;
          this.$nextTick(() => {
            this.$refs["previewImage"].getPreviewData(this.form);
          });
        } else {
          this.changeTap();
        }
      });
    },
    /**
     * 获取教练集合
     */
    getCoachInfo() {
      this.$http
        .post(apis.coachInfoList, {
          pageNum: 1,
          pageSize: 100000,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.coachList = res.data.rows;
          }
        });
    },
    removeImage(index) {
      this.form.filesPicturesPath.splice(index, 1);
      this.form.files.splice(index, 1);
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.pictureUrl = res.data.url;
      this.form.picturePath = res.data.path;
      this.$refs.form.validateField("picturePath");
    },
    uploadError(res) {
      console.log(res);
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    onCoachHandle() {
      this.form.coachIds = [];
      this.form.coachIds.push(this.form.coachId);
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) {
            return;
          }
          this.flag = true;
          if (this.isEdit) {
            this.$http
              .put(apis.courseManage, {
                ...this.form,
                // coachIds: [this.form.coachId],
              })
              .then((res) => {
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.flag = false;
                  this.closeDialog();
                } else {
                  this.flag = false;
                }
              });
          } else {
            this.$http.post(apis.courseManage, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false;
                this.closeDialog();
              } else {
                this.flag = false;
              }
            });
          }
        } else {
          this.changeTap();
        }
      });
      console.log(`我执行了点确定的业务需求`);
    },
    // 切换tab类型 保存时，如果没有填的要切换tab
    changeTap() {
      if (!this.form.registerCodes || !this.form.detail) {
        this.form.status = "second";
      } else {
        this.form.status = "first";
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      this.getCoachInfo();
      // 在这里获取回显的数据
      this.$http.get(`${apis.courseManage}/${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...this.form,
            ...res.data.data,
            coachIds:
              res.data.data.coaches.length > 0
                ? [res.data.data.coaches[0].id]
                : [],
            coachId:
              res.data.data.coaches.length > 0
                ? res.data.data.coaches[0].id
                : "",
            price: res.data.data.price.toFixed(2),
            orgPrice: res.data.data.orgPrice.toFixed(2),
          };
        }
      });
    },
    /**
     * @function 获取全部的角色（树状）
     */
    getRoleData() {
      this.$http.post(apis.User_GetAllRole).then((res) => {
        if (res.data.code === 0) {
          this.roleData = res.data.data.map((item) => {
            const items = { ...item };
            items.key = item.code;
            items.label = item.value;
            return items;
          });
        }
      });
    },
    /**
     * @description: 点击下一步
     * @param {*}
     * @return {*}
     */
    goDetailed() {
      this.form.status = "second";
    },
    numberChange (val, maxNum, type) {
      this.numChange(Number(val), type)
      //重新渲染
      this.$nextTick(()=>{
        //比较输入的值和最大值，返回小的
        let num = Math.min(Number(val),maxNum)
        //输入负值的情况下， = 0（可根据实际需求更该）
        if(num < 0) {
          num = 0   
        }
        this.numChange(num, type)
      })
    },
    /**
     * @function: 对不同的输入框进行赋值
     */
    numChange(num, type) {
      switch (type) {
          case 'price':
            this.form.price = num
            break
          case 'orgPrice':
            this.form.orgPrice = num
            break
          case 'peopleQty':
            this.form.peopleQty = num
            break
          default:
            break
        }
    }
  },
};
</script>
<style lang="less" scoped>
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.footerBtn {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 219px !important;
    }
  }
}
</style>
